import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Price, Star } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  image?: ImageProps
  languageCode: string
  pricefrom?: string
  style?: any
  title: string
  URL: string
  validity?: string
  weight?: number
}

export const Offer = memo(function Offer({
  className,
  image,
  languageCode,
  pricefrom,
  style,
  title,
  URL,
  validity,
}: Props) {
  return (
    <Container className={className} style={style} to={URL}>
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>
      <Wrapper>
        <Title>{title}</Title>
        <Validity>
          <Star />
          {validity}
        </Validity>
        {pricefrom ? (
          <PriceWrapper row>
            <Price />
            <Pricefrom dangerouslySetInnerHTML={{ __html: pricefrom }} />
          </PriceWrapper>
        ) : null}
        <CTA
          className="offer-cta"
          label={useVocabularyData('discover-offer', languageCode)}
          URL={URL ? URL : ''}
          variant="simple"
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5.625rem 5.625rem 8.125rem;
  position: absolute;
  top: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:hover {
    .offer-cta {
      svg {
        transform: translate(0.375rem, 0.1875rem);
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 1.875rem;
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Wrapper = styled.div`
  width: 100%;
  background: ${rgba(theme.colors.variants.primaryLight2, 0.9)};
  padding: 5.125rem 5.375rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1199px) {
    padding: 1.875rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.625rem;
  line-height: 2rem;
  margin-bottom: 1.875rem;
`
const Validity = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.125rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1rem;

  svg {
    fill: ${({ theme }) => theme.colors.variants.primaryDark};
    margin-right: 0.75rem;
    height: 1.125rem;
    vertical-align: middle;
  }
`

const PriceWrapper = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1rem;

  svg {
    fill: ${({ theme }) => theme.colors.variants.primaryDark};
    margin-right: 0.75rem;
    height: 1.125rem;
    vertical-align: middle;
  }

  b {
    font-weight: 500;
  }
`
const Pricefrom = styled.div``

const CTA = styled(Button)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: 3.125rem;

  svg {
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`
