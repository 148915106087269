import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

import { Hotel, Props as HotelProps } from './Hotel'

export interface Props {
  hotels: HotelProps[]
  languageCode: string
}

export const OtherHotels = memo(function OtherHotels({
  hotels,
  languageCode,
}: Props) {
  if (hotels.length < 1) {
    return null
  }

  const [_currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    slides: {
      perView: 3,
      spacing: 30,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1199px)': {
        slides: {
          perView: 3.01,
          spacing: 20,
        },
      },
      '(max-width: 1023px)': {
        slides: {
          perView: 2.01,
          spacing: 12,
        },
      },
      '(max-width: 767px)': {
        slides: {
          perView: 1.01,
          spacing: 12,
        },
      },
    },
  })

  return (
    <Container>
      <Head dial={4} row space="between" wrap>
        <Title>{useVocabularyData('other-hotels', languageCode)}</Title>
        {hotels.length > 3 ? (
          <Arrows row>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              variant="small"
            />
            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              variant="small"
            />
          </Arrows>
        ) : null}
      </Head>
      <Wrapper>
        <Hotels ref={sliderRef}>
          {hotels.map((item, index) => (
            <Hotel className="keen-slider__slide" key={index} {...item} />
          ))}
        </Hotels>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem auto 0;

  @media (max-width: 1199px) {
    margin-top: 7.5rem;
  }
`

const Head = styled(FlexBox)`
  padding: 0 18.264vw;
  margin-bottom: 3rem;

  @media (max-width: 1199px) {
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    justify-content: center;
    text-align: center;
  }
`

const Arrows = styled(FlexBox)`
  transform: translateX(0.9375rem);
  > div {
    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Wrapper = styled.div`
  padding: 0 18.264vw;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    height: 50%;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    padding: 0 1.875rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.625rem;

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Hotels = styled.div`
  display: flex;
  overflow: hidden;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }

  @media (max-width: 1199px) {
    overflow: visible;
  }
`
