import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  image?: ImageProps
  title?: string
  website?: string
}

export const Hotel = memo(function Hotel({
  className,
  image,
  title,
  website,
}: Props) {
  return (
    <Container
      className={className}
      href={website || undefined}
      rel="noopener"
      target="_blank"
    >
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}
      {title ? <Title className="hotel-title">{title}</Title> : null}
    </Container>
  )
})

const Container = styled.a`
  width: calc(33.333% - 1.875rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-bottom: 17.1%;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    img {
      transform: scale(1.1);
    }

    .hotel-title:after {
      width: 100%;
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${rgba(theme.colors.variants.neutralDark2, 0.6)};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out !important;
  }

  @media (max-width: 1023px) {
    padding-bottom: 26%;
  }

  @media (max-width: 767px) {
    padding-bottom: 42%;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.5rem;
  position: absolute;
  bottom: 2.4188rem;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s ease-in-out;
  text-align: center;
  z-index: 2;

  &:after {
    content: '';
    width: 1.875rem;
    height: 0.125rem;
    position: absolute;
    bottom: -0.375rem;
    left: 50%;
    transform: translateX(-50%);
    background: ${({ theme }) => theme.colors.variants.primaryLight2};
    transition: 0.2s ease-in-out;
  }
`
