import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Offer, Props as OfferProps } from './Offer'

const AUTOPLAY_TIMER = 5000

export interface Props {
  cta?: string
  description?: string
  label?: string
  languageCode: string
  offers: OfferProps[]
  title?: string
}

export const OffersSlider = memo(function OffersSlider({
  cta,
  description,
  label,
  languageCode,
  offers,
  title,
}: Props) {
  if (offers.length < 1 || !title) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = React.useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: false,
    loop: offers.length > 1 ? true : false,
    slides: offers.length,
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      offers.length > 1 ? setOpacities(newOpacities) : null
    },
    breakpoints: {
      '(max-width: 767px)': {
        drag: true,
      },
    },
  })

  useEffect(() => {
    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container dial={4} row stretch tag="section" wrap>
      <WrapperText>
        <FadeInUp>
          {label ? <Label>{label}</Label> : null}
          {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
          {cta ? (
            <CTA
              label={useVocabularyData('discover-offers', languageCode)}
              URL={cta}
            />
          ) : null}
        </FadeInUp>
      </WrapperText>
      <WrapperSlider>
        <Slider ref={sliderRef}>
          {offers.map((item, index) => (
            <Offer
              key={index}
              style={{
                opacity: opacities[index],
                zIndex: opacities[index],
              }}
              {...item}
            />
          ))}
        </Slider>

        {offers.length > 1 ? (
          <Arrows row>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
            />
            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
            />
          </Arrows>
        ) : null}
      </WrapperSlider>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 0 auto;
`
const WrapperText = styled.div`
  width: 50%;
  text-align: left;
  padding: 12.6875rem 10.417vw 12.6875rem 7.292vw;

  @media (max-width: 1199px) {
    padding: 5rem 1.875rem 5rem 1.875rem;
  }

  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    padding-bottom: 0;
    text-align: center;
  }
`

const Label = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-size: 1.25rem;
  line-height: 1.125rem;
  font-weight: 300;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 3.125rem;
  line-height: 4.375rem;
  font-weight: 500;
  max-width: 29.0625rem;
  margin-bottom: 5rem;

  b {
    font-style: italic;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.variants.primaryLight2};
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
    margin-bottom: 2.5rem;
  }

  @media (max-width: 1023px) {
    margin-bottom: 1.875rem;
    max-width: none;
  }
`

const Description = styled.div`
  max-width: 37.5rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  font-weight: 300;
  padding-left: 5.5rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 1rem;
    background-color: ${({ theme }) => theme.colors.variants.primaryLight2};
    height: 0.125rem;
    width: 3rem;
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    padding-left: 4.875rem;
  }

  @media (max-width: 1023px) {
    max-width: none;
    padding-left: 0;

    &:before {
      display: none;
    }
  }
`

const WrapperSlider = styled.div`
  width: 50%;
  padding: 6.25rem 7.292vw 6.25rem 0;
  position: relative;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 1199px) {
    padding: 1.875rem 1.875rem 1.875rem 0;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Slider = styled.div`
  display: flex;
  height: 100%;
  min-height: 37.5rem;
  position: relative;

  @media (max-width: 1199px) {
    min-height: 28.125rem;
  }
`

const Arrows = styled(FlexBox)`
  justify-content: center;
  position: absolute;
  bottom: 6.25rem;
  right: 7.292vw;
  left: 0;
  z-index: 2;

  @media (max-width: 1199px) {
    bottom: 1.875rem;
    right: 1.875rem;
  }
`
const CTA = styled(Button)`
  height: 5rem;
  line-height: 5rem;
  margin-top: 4.0625rem;
`
