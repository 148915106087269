import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  label?: string
  mainImage?: ImageProps
  secondaryImage?: ImageProps
  title?: string
}

export const RoomsPreview = memo(function RoomsPreview({
  cta,
  description,
  mainImage,
  label,
  secondaryImage,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container row tag="section" wrap dial={5} stretch>
      {mainImage ? (
        <Background>
          <LazyLoadComponent>
            <Image {...mainImage} />
          </LazyLoadComponent>
        </Background>
      ) : null}

      <Wrapper>
        <FadeInUp>
          {label ? <Label>{label}</Label> : null}
          {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
          <Line className="line" variant="compact-invert" />
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
          {cta ? (
            <Button className="rooms-button" variant="dark" {...cta} />
          ) : null}
        </FadeInUp>
      </Wrapper>

      {secondaryImage ? (
        <Background>
          <LazyLoadComponent>
            <Image {...secondaryImage} />
          </LazyLoadComponent>
        </Background>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 9.375rem auto 12.5rem;

  @media (max-width: 1199px) {
    margin: 7.5rem 0 5.625rem;
  }
`

const Wrapper = styled.div`
  flex: 1;
  text-align: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  background-color: ${({ theme }) => theme.colors.variants.primaryLight2};
  padding: 5rem;
  margin-left: 1.875rem;
  margin-right: 1.875rem;

  .rooms-button {
    min-width: 12.5rem;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    width: 3.75rem;
  }

  @media (max-width: 1199px) {
    width: auto;
  }

  @media (max-width: 1023px) {
    padding: 5rem 1.875rem;
    margin-left: 0;
    margin-right: 0;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.875rem;
  font-weight: 500;
  margin: 0 auto 1.875rem;
  position: relative;
  max-width: 26rem;

  b {
    font-style: italic;
    font-weight: normal;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Label = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 300;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 300;
  margin: 3.75rem auto 3.75rem;
  max-width: 26rem;

  @media (max-width: 1199px) {
    font-size: 1.0625rem;
    margin-top: 2.5rem;
    max-width: none;
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
  }
`

const Background = styled.div`
  width: 27.917vw;
  height: auto;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: 18%;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`
