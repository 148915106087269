import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  cta?: ButtonProps
  label?: string
  services?: ServiceProps[]
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  cta,
  label,
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container>
      <Wrapper dial={5}>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <Line className="line" variant="compact" />
          </FadeInUp>
        ) : null}

        {cta ? <CTA variant="simple" icon="chevronright" {...cta} /> : null}
      </Wrapper>
      <Services row wrap stretch>
        {services.slice(0, 1).map((item, index) => (
          <Service key={index} {...item} />
        ))}

        {services.slice(1, services.length).map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  margin: 10rem -1.875rem 10rem auto;
  padding: 3.75rem 7.292vw 0;
  position: relative;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 7.5rem -1.875rem 7.5rem auto;
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin: 7.5rem -0.75rem 0 auto;
  }

  @media (max-width: 767px) {
    margin: 5.625rem -0.75rem 0 auto;
  }
`

const Wrapper = styled(FlexBox)`
  padding: 0 3.75rem;
  margin-bottom: 3.75rem;

  .line {
    width: 3.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 1199px) {
    padding: 0;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 300;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  max-width: 25.375rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.875rem;
  font-weight: 500;
  text-align: center;
  position: relative;

  b {
    color: ${({ theme }) => theme.colors.variants.primaryLight2};
    font-weight: normal;
    font-style: italic;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Services = styled(FlexBox)``

const CTA = styled(Button)`
  margin: 3.75rem auto 0;
`
