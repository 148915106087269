import styled from '@emotion/styled'
import { Hero } from 'app/components/Hero'
import { InteractiveMap } from 'app/components/InteractiveMap'
import { Intro } from 'app/components/Intro'
import { OffersSlider } from 'app/components/OffersSlider'
import { OtherHotels } from 'app/components/OtherHotels'
import { RoomsPreview } from 'app/components/RoomsPreview'
import { SEO } from 'app/components/SEO'
import { ServicesPreview } from 'app/components/ServicesPreview'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          stickerProps={context.stickerProps ? context.stickerProps : null}
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? (
        <Intro variant="default" {...context.introProps} />
      ) : null}
      {context.roomsPreviewProps ? (
        <RoomsPreview {...context.roomsPreviewProps} />
      ) : null}
      {context.servicesPreviewProps ? (
        <ServicesPreview {...context.servicesPreviewProps} />
      ) : null}
      {context.interactiveMapProps ? (
        <InteractiveMap {...context.interactiveMapProps} />
      ) : null}
      {context.offersSliderProps ? (
        <OffersSlider {...context.offersSliderProps} />
      ) : null}
      {context.otherHotelsProps ? (
        <OtherHotels {...context.otherHotelsProps} />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
