import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { Starfull } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Poi, Props as PoiProps } from './Poi'

export interface Props {
  cta?: ButtonProps
  description?: string
  poi: PoiProps[]
  label?: string
  siteName?: string
  title?: string
}

export const InteractiveMap = memo(function InteractiveMap({
  cta,
  description,
  poi,
  label,
  siteName,
  title,
}: Props) {
  if (poi.length < 1) {
    return null
  }

  return (
    <Container>
      <FadeInUp>
        <Head>
          {label ? <Label>{label}</Label> : null}
          {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
          <Line className="line" variant="compact" />
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
          {cta ? (
            <Button className="cta-map" variant="simple" {...cta} />
          ) : null}
        </Head>
      </FadeInUp>
      <Map>
        {poi.map((item, index) => (
          <Poi key={index} {...item} />
        ))}
        <Hotel dial={5} row wrap>
          {siteName ? <SiteName>{siteName}</SiteName> : null}
          <Starfull />
          <Starfull />
          <Starfull />
        </Hotel>
      </Map>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  text-align: center;
  margin-top: 12.5rem;

  @media (max-width: 1199px) {
    margin-top: 7.5rem;
  }

  @media (max-width: 767px) {
    margin-top: 5.625rem;
  }
`

const Head = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 6.25rem 7.292vw;

  .line {
    margin: 1.875rem auto 0;
  }

  @media (max-width: 1199px) {
    padding-top: 5rem;
  }
  @media (max-width: 1023px) {
    padding: 3.75rem 1.875rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 3.125rem;
  line-height: 3.875rem;
  font-weight: 500;

  b {
    font-style: italic;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.variants.primaryLight2};
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Label = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 300;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`
const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 300;
  margin: 3.75rem auto 0px;
  max-width: 50.875rem;

  @media (max-width: 1199px) {
    font-size: 1.0625rem;
    margin-top: 2.5rem;
    margin-bottom: 0;
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
  }
`

const Hotel = styled(FlexBox)`
  width: 7.5rem;
  height: 5.375rem;
  position: absolute;
  top: 31%;
  left: 71.5%;
  background: ${({ theme }) => theme.colors.variants.primaryLight2};
  padding: 1.25rem 1rem;

  &:after {
    content: '';
    position: absolute;
    bottom: -1.125rem;
    left: 50%;
    margin-left: -0.9063rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1.125rem 0.9063rem 0 0.9063rem;
    border-color: ${({ theme }) => theme.colors.variants.primaryLight2}
      transparent transparent transparent;
  }

  svg {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.65rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
`

const SiteName = styled.div`
  text-transform: uppercase;
  font-size: 0.9375rem;
  line-height: 1rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  width: 100%;
  margin-bottom: 0.2875rem;
`

const Map = styled.div`
  width: 100%;
  padding-bottom: 48.61125%;
  background: url('/interactive-map.jpg') no-repeat center;
  background-size: cover;
  position: relative;

  @media (max-width: 1023px) {
    display: none;
  }
`
