import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useRef, useState } from 'react'

export interface Props {
  id: number
  label: string
  x_axis: number
  y_axis: number
}

export const Poi = memo(function Poi({ id, label, x_axis, y_axis }: Props) {
  const _ = require('lodash')
  let labelClass = _.kebabCase(`${label}`)
  return (
    <Container
      className={`active` + ` ` + labelClass}
      style={{ top: `${y_axis}%`, left: `${x_axis}%` }}
    >
      <Tooltip className="visible">
        <Label>{label}</Label>
      </Tooltip>
    </Container>
  )
})

const Container = styled.div`
  width: 0.875rem;
  height: 0.875rem;
  background: ${rgba(theme.colors.variants.primaryLight2, 1)};
  border: 0.125rem solid ${rgba(theme.colors.variants.neutralLight4, 1)};
  opacity: 0.75;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  transition: 0.3s ease-in-out;
  &:hover {
    opacity: 1;
  }
  &.active {
    opacity: 1;
  }
`

const Tooltip = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 0.625rem 0.75rem;
  opacity: 0;
  position: absolute;
  bottom: calc(100% + 1.75rem);
  left: 50%;
  transform: translate(-50%, 0.75rem);
  transition: 0.3s ease-in-out;
  z-index: -1;
  &.visible {
    opacity: 1;
    z-index: 1;
  }

  .piazza-s-marco &,
  .stazione-venezia-s-lucia & {
    transform: translate(-50%, 2rem);
    top: calc(100% - 1rem);
    bottom: auto;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
`
